/**
 * HSSE Dashboard Styles
**/

.csv-input {
  padding: 10px;
  display: block;
  margin: 15px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.csv-container {
  text-align: center;
  padding: 15px;
  margin: 10px auto;
}

.csv-container p {
  padding-top: 10px;
  color: #666;
}
